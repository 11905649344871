import React from "react";
import { useTranslation } from "react-i18next";
import About from "./components/About";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Frameworks from "./components/Frameworks";

import Header from "./components/Header";
import Nombers from "./components/Nombers";
import Services from "./components/Services";
import "./Home.css";

class Home extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "./assets/js/scripts.min.js";
    script.async = true;
    script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <Header />
          <About />
          <Services />
          <Features />
          <Nombers />
          <Frameworks />
          <Clients />

          <section
            className="data token-data section section--no-pad-bot"
            style={{ display: "none" }}
          >
            <div className="container">
              <div className="row chart__row align-items-center">
                <div className="col-lg-6">
                  <div className="chart">
                    <img className="chart__bg" src="img/chart-bg.png" alt="" />
                    <div className="chart__wrap">
                      <canvas id="myChart" width={400} height={400} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Contact />
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
