import React from "react";
import { useTranslation } from "react-i18next";



const Frameworks = () => {
    const { t } = useTranslation();

  return (
    <React.Fragment>
          <section id="Frameworks" className="section section--no-pad-bot facts">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="section-header section-header--animated section-header--center section-header--small-margin">
                    <h4>{t("Frameworks I work with")}</h4>
                    <h2>{t("Frameworks I work with")}</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="facts__line">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="facts__line-list">
                      <div className="facts__item">
                        <img
                          src="./assets/img/Framework2.png"
                          className="facts__icon"
                          alt=""
                        />
                        <div className="facts__title">Laravel</div>
                      </div>
                      <div className="facts__item">
                        <img
                          src="./assets/img/Framework3.png"
                          className="facts__icon"
                          alt=""
                        />
                        <div className="facts__title">Flutter</div>
                      </div>
                      <div className="facts__item">
                        <img
                          src="./assets/img/Framework4.png"
                          className="facts__icon"
                          alt=""
                        />
                        <div className="facts__title">React.js</div>
                      </div>
                      <div className="facts__item">
                        <img
                          src="./assets/img/Framework5.png"
                          className="facts__icon"
                          alt=""
                        />
                        <div className="facts__title">Node.js</div>
                      </div>
                      <div className="facts__item">
                        <img
                          src="./assets/img/Framework6.png"
                          className="facts__icon"
                          alt=""
                        />
                        <div className="facts__title">ASP.NET</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src="./assets/img/facts-bg.png" className="facts__bg" alt="" />
            </div>
          </section>
    </React.Fragment>
  );
};
export default Frameworks;
