import React from "react";

import { useTranslation } from "react-i18next";


const About = () => {
    const { t } = useTranslation();

  return (
    <React.Fragment>
          <section className="section about" id="about">
            <div className="container">
              <div className="row">
                <div data-aos="fade-right" className="col-lg-5">
                  <div className="section-header section-header--animated section-header--tire section-header--small-margin">
                    <h4 id="about-me-ar">{t("About Me")}</h4>
                    <h2>
                    {t("Rayan Lubbad")} <span> {t("PRODUCT MANAGER , DEVELOPER  ")}</span>
                    </h2>
                  </div>
                  <div className="about__animated-content">
                    <p>
                    {t("Aboutme")}
                    </p>

                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 align-items-center">
                  <img
                    src="./assets/img/about-img.png"
                    className="about__img img-responsive"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <img
              src="./assets/img/about-bg.png"
              data-jarallax-element={40}
              alt=""
              className="about__bg"
            />
          </section>
    </React.Fragment>
  );
};
export default About;
