import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [isSentRequest, setIsSentRequest] = useState(false);
  const [IsFailed, setIsFailed] = useState(false);
  const [IsSuccessed, setIsSuccessed] = useState(false);

  const form = useRef();
  const { t } = useTranslation();

  const sendEmail = (e) => {
    setIsSentRequest(true);

    e.preventDefault();
    if (!IsSuccessed) {
      emailjs
        .sendForm(
          "service_9y7n2nq",
          "template_gaipzgs",
          form.current,
          "xEwbPm-gv9PbTIh57"
        )
        .then(
          (result) => {
            setIsSuccessed(true);
          },
          (error) => {
            setIsSentRequest(false);
            setIsFailed(true);
          }
        );
    }
  };
  return (
    <React.Fragment>
      <section id="Contact" className="section contact">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-header section-header--center section-header--medium-margin">
                <h4> {t("Contact me")}</h4>
                <h2>{t("Contact me")}</h2>
              </div>
              <form ref={form} onSubmit={sendEmail} className="form ">
                <input
                  type="text"
                  name="Name"
                  className="form__input"
                  placeholder={t("Name")}
                  required
                />
                <input
                  type="email"
                  name="Email"
                  className="form__input"
                  placeholder={t("Email")}
                  required
                />
                <textarea
                  name="Message"
                  className="form__textarea"
                  placeholder={t("Message")}
                  defaultValue={""}
                  required
                />
                <div style={{ display: IsSuccessed ? "block" : "none" }}>
                  <div class="alert alert-success d-flex align-items-center justify-content-center ">
                    <div>{t("Sent Successfully")}</div>
                  </div>
                </div>

                <div style={{ display: IsFailed ? "block" : "none" }}>
                  <div
                    class="alert alert-danger d-flex align-items-center justify-content-center "
                    role="alert"
                  >
                    <div>{t("Message failed , please try again later.")}</div>
                  </div>
                </div>
                <button
                  className="form__btn btn btn--uppercase btn--orange"
                  disabled={isSentRequest && !IsSuccessed ? true : false}
                >
                  {isSentRequest && !IsSuccessed ? (
                    <div> {t("Loading...")} </div>
                  ) : (
                    <span>{t("Send message")}</span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
        <img
          src="./assets/img/subscribe-bg.png"
          className="contact-bg"
          alt=""
        />
      </section>
    </React.Fragment>
  );
};
export default Contact;
