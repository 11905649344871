import React from "react";
import { useTranslation } from "react-i18next";

const Features = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section id="Products" className="section cases">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="section-header section-header--animated section-header--center section-header--medium-margin">
                <h4>{t("Products")}</h4>
                <h2>{t("Products I am proud I worked on")}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col cases__list">
              <div data-aos="fade-right" className="cases__item">
                <div className="cases__item-content">
                  <div className="cases__item-title">
                    {t("Aramco world cup")}
                  </div>
                  <p className="cases__item-text">{t("Aramco desc")}</p>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay={200}
                className="cases__item"
              >
                <div className="cases__item-content">
                  <div className="cases__item-title">{t("Wotn Tickets")}</div>
                  <p className="cases__item-text">{t("Wotn desc")}</p>
                </div>
              </div>
              <div data-aos="fade-right" className="cases__item">
                <div className="cases__item-content">
                  <div className="cases__item-title">{t("Sani")}</div>
                  <p className="cases__item-text">{t("Sani desc")}</p>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay={200}
                className="cases__item"
              >
                <div className="cases__item-content">
                  <div className="cases__item-title">{t("DSA KSU")}</div>
                  <p className="cases__item-text">{t("DSA KSU desc")}</p>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay={200}
                className="cases__item"
              >
                <div className="cases__item-content">
                  <div className="cases__item-title">{t("Muali")}</div>
                  <p className="cases__item-text">{t("Muali desc")}</p>
                </div>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay={200}
                className="cases__item"
              >
                <div className="cases__item-content">
                  <div className="cases__item-title">{t("Lammah")}</div>
                  <p className="cases__item-text">{t("Lammah desc")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="./assets/img/cases-bg.png" className="cases__bg" alt="" />
        <img
          src="./assets/img/cases-imgs.png"
          className="cases__elements"
          alt=""
        />
      </section>
    </React.Fragment>
  );
};
export default Features;
