import React from "react";

import { useTranslation } from "react-i18next";

const Nombers = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="data" id="stat">
        <div className="container data__container">
          <div className="row">
            <div className="col">
              <img
                src="./assets/img/data-bg.png"
                className="data__img"
                alt=""
              />
              <div className="counter__item counter__item-1">
                <div className="counter__item-title">
                  {t("Trusted by more than")}
                </div>
                <div className="data-text2">
                  <div className="counter counter__item-value counter__item-value--blue numscroller">
                    5
                  </div>
                  <div>{t("Entities")}</div>
                </div>
              </div>
              <div className="counter__item counter__item-2">
                <div className="counter__item-title">{t("Websites ")}</div>
                <div className="data-text2">

                <div className="counter counter__item-value counter__item-value--pink">
                  20
                  </div><h5>+</h5>
                </div>
              </div>

              <div className="counter__item counter__item-4">
                <div className="counter__item-title">{t("Mobile apps")}</div>
                <div className="data-text2">

                <div className="counter counter__item-value counter__item-value--green">
                   5 
                </div><h5>+</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="./assets/img/data-bg-space.png" className="data__bg" alt="" />
      </section>
    </React.Fragment>
  );
};
export default Nombers;
