import React from "react";

import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <a href="https://www.rayanlubbad.com" className="logo">
                <div className="logo__title">Rayan Lubbad</div>
              </a>
              <div className="copyright">© 2023, Rayan Lubbad </div>
            </div>
            <div className="col-lg-4 socials">
              <div className="social-block">
                <div className="social-block__title">
                  {t("Stay connected:")}
                </div>
                <ul className="social-list">
                  <li className="social-list__item">
                    <a
                      href="https://twitter.com/LubbadRayan"
                      className="social-list__link"
                    >
                      {" "}
                      <i class="fa-brands fa-linkedin">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0,0,256,256"
                          width="24px"
                          height="24px"
                          fillRule="nonzero"
                        >
                          <g
                            fill="#ffffff"
                            fillRule="nonzero"
                            stroke="none"
                            strokeWidth={1}
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit={10}
                            strokeDasharray
                            strokeDashoffset={0}
                            fontFamily="none"
                            fontWeight="none"
                            fontSize="none"
                            textAnchor="none"
                            style={{ mixBlendMode: "normal" }}
                          >
                            <g transform="scale(8.53333,8.53333)">
                              <path d="M28,6.937c-0.957,0.425 -1.985,0.711 -3.064,0.84c1.102,-0.66 1.947,-1.705 2.345,-2.951c-1.03,0.611 -2.172,1.055 -3.388,1.295c-0.973,-1.037 -2.359,-1.685 -3.893,-1.685c-2.946,0 -5.334,2.389 -5.334,5.334c0,0.418 0.048,0.826 0.138,1.215c-4.433,-0.222 -8.363,-2.346 -10.995,-5.574c-0.458,0.788 -0.721,1.704 -0.721,2.683c0,1.85 0.941,3.483 2.372,4.439c-0.874,-0.028 -1.697,-0.268 -2.416,-0.667c0,0.023 0,0.044 0,0.067c0,2.585 1.838,4.741 4.279,5.23c-0.447,0.122 -0.919,0.187 -1.406,0.187c-0.343,0 -0.678,-0.034 -1.003,-0.095c0.679,2.119 2.649,3.662 4.983,3.705c-1.825,1.431 -4.125,2.284 -6.625,2.284c-0.43,0 -0.855,-0.025 -1.273,-0.075c2.361,1.513 5.164,2.396 8.177,2.396c9.812,0 15.176,-8.128 15.176,-15.177c0,-0.231 -0.005,-0.461 -0.015,-0.69c1.043,-0.753 1.948,-1.692 2.663,-2.761z" />
                            </g>
                          </g>
                        </svg>{" "}
                      </i>
                    </a>
                  </li>
                  <li className="social-list__item">
                    <a
                      href="https://www.linkedin.com/in/rayan-lubbad-b60a98230/"
                      className="social-list__link fa-linkedin"
                    >
                      <i class="fa-brands fa-linkedin">
                        <svg
                          fill="#ffffff"
                          height="16px"
                          width="16px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 310 310"
                          xmlSpace="preserve"
                          stroke="#ffffff"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g id="XMLID_801_">
                              {" "}
                              <path
                                id="XMLID_802_"
                                d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73 C77.16,101.969,74.922,99.73,72.16,99.73z"
                              />{" "}
                              <path
                                id="XMLID_803_"
                                d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4 c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                              />{" "}
                              <path
                                id="XMLID_804_"
                                d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599 c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319 c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995 C310,145.43,300.549,94.761,230.454,94.761z"
                              />{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </i>
                    </a>
                  </li>
                  <li className="social-list__item">
                    <a
                      href="https://github.com/rayl999"
                      className="social-list__link fa-linkedin"
                    >
                      <i class="fa-brands fa-linkedin">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          viewBox="0,0,256,256"
                          width="24px"
                          height="24px"
                          fillRule="nonzero"
                        >
                          <g
                            fill="#ffffff"
                            fillRule="nonzero"
                            stroke="none"
                            strokeWidth={1}
                            strokeLinecap="butt"
                            strokeLinejoin="miter"
                            strokeMiterlimit={10}
                            strokeDasharray
                            strokeDashoffset={0}
                            fontFamily="none"
                            fontWeight="none"
                            fontSize="none"
                            textAnchor="none"
                            style={{ mixBlendMode: "normal" }}
                          >
                            <g transform="scale(10.66667,10.66667)">
                              <path d="M10.9,2.1c-4.6,0.5 -8.3,4.2 -8.8,8.7c-0.5,4.7 2.2,8.9 6.3,10.5c0.3,0.1 0.6,-0.1 0.6,-0.5v-1.6c0,0 -0.4,0.1 -0.9,0.1c-1.4,0 -2,-1.2 -2.1,-1.9c-0.1,-0.4 -0.3,-0.7 -0.6,-1c-0.3,-0.1 -0.4,-0.1 -0.4,-0.2c0,-0.2 0.3,-0.2 0.4,-0.2c0.6,0 1.1,0.7 1.3,1c0.5,0.8 1.1,1 1.4,1c0.4,0 0.7,-0.1 0.9,-0.2c0.1,-0.7 0.4,-1.4 1,-1.8c-2.3,-0.5 -4,-1.8 -4,-4c0,-1.1 0.5,-2.2 1.2,-3c-0.1,-0.2 -0.2,-0.7 -0.2,-1.4c0,-0.4 0,-1 0.3,-1.6c0,0 1.4,0 2.8,1.3c0.5,-0.2 1.2,-0.3 1.9,-0.3c0.7,0 1.4,0.1 2,0.3c1.3,-1.3 2.8,-1.3 2.8,-1.3c0.2,0.6 0.2,1.2 0.2,1.6c0,0.8 -0.1,1.2 -0.2,1.4c0.7,0.8 1.2,1.8 1.2,3c0,2.2 -1.7,3.5 -4,4c0.6,0.5 1,1.4 1,2.3v2.6c0,0.3 0.3,0.6 0.7,0.5c3.7,-1.5 6.3,-5.1 6.3,-9.3c0,-6 -5.1,-10.7 -11.1,-10z" />
                            </g>
                          </g>
                        </svg>
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form__title">Email :</div>
              <a href="mailto: info@rayanlubbad.com" className="form__title">
                info@rayanlubbad.com
              </a>

              <div className="form__row"></div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
export default Footer;
