import React from "react";
import { useTranslation } from "react-i18next";



const Services = () => {
    const { t } = useTranslation();

  return (
    <React.Fragment>
          <section
            className="section section--no-pad-bot services"
            id="services"
          >
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="section-header section-header--animated section-header--center section-header--medium-margin">
                    <h4>{t("What do I do ?")}</h4>
                    <h2>{t("What do I do ?")}</h2>
                  </div>
                  <div className="services__items">
                    <div className="services__left">
                      <div data-aos="fade-up" className="service">
                        <div
                          className="service__bg"
                          style={{
                            backgroundColor: "#e85f70",
                            boxShadow: "0 0 51px rgba(232, 95, 112, 0.74)",
                          }}
                        />
                        <img src="./assets/img/service-icon-1.svg" alt="" />
                        <div className="service__title">{t("Web development")}</div>
                      </div>
                      <div
                        data-aos="fade-up"
                        data-aos-delay={200}
                        className="service"
                      >
                        <div
                          className="service__bg"
                          style={{
                            backgroundColor: "#fa8936",
                            backgroundImage:
                              "linear-gradient(-234deg, #ea9d64 0%, #fa8936 100%)",
                            boxShadow: "0 0 51px rgba(250, 137, 54, 0.74)",
                          }}
                        />
                        <img src="./assets/img/service-icon-2.svg" alt="" />
                        <div className="service__title">{t("Mobile development")}</div>
                      </div>
                    </div>
                    <div className="services__right">
                      <div
                        data-aos="fade-up"
                        data-aos-delay={400}
                        className="service"
                      >
                        <div
                          className="service__bg"
                          style={{
                            backgroundImage:
                              "linear-gradient(-234deg, #6ae472 0%, #4bc253 100%)",
                            boxShadow: "0 0 51px rgba(75, 194, 83, 0.74)",
                          }}
                        />
                        <img src="./assets/img/service-icon-3.svg" alt="" />
                        <div className="service__title">{t("Business analysis")}</div>
                      </div>
                      <div
                        data-aos="fade-up"
                        data-aos-delay={600}
                        className="service"
                      >
                        <div
                          className="service__bg"
                          style={{
                            backgroundColor: "#0090d5",
                            backgroundImage:
                              "linear-gradient(-234deg, #29aceb 0%, #0090d5 100%)",
                            boxShadow: "0 0 51px rgba(0, 144, 213, 0.74)",
                          }}
                        />
                        <img src="./assets/img/service-icon-4.svg" alt="" />
                        <div className="service__title">{t("Product management")}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              src="./assets/img/services-bg1.png"
              alt=""
              className="services__bg"
            />
            <img
              src="./assets/img/services-bg-1.png"
              className="services__cosmos"
              alt=""
            />
          </section>
    </React.Fragment>
  );
};
export default Services;
