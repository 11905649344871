import React from "react";

import { useTranslation } from "react-i18next";


const Clients = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
          <section className="data token-data section section--no-pad-bot">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="section-header section-header--animated section-header--medium-margin section-header--center">
                    <h4>{t("Trusted by")}</h4>
                    <h2>{t("Trusted by")}</h2>
                    <div className="bg-title">{t("Trusted by")}</div>
                  </div>
                </div>
              </div>

            </div>
          </section>

          <section className="partners">
            <div className="container">
              <div className="row">
                <div className="col">

                  <div className="logoes">
                    <div>
                      <img src="./assets/img/partner1.png" alt="" />
                    </div>
                    <div>
                      <img src="./assets/img/partner2.png" alt="" />
                    </div>
                    <div>
                      <img src="./assets/img/partner3.png" alt="" />
                    </div>
                    <div>
                      <img src="./assets/img/partner6.png" alt="" />
                    </div>
                    <div>
                      <img src="./assets/img/partner5.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    </React.Fragment>
  );
};
export default Clients;
